import useLoadScript from "../hooks/useLoadScript";
import Preloader from "../components/Preloader";
import Header from "../components/Header";
import Footer from "../components/Footer";

function About() {
    useLoadScript([
        {src: "/js/jquery.min.js", id: "jquery-script"},
        {src: "/js/plugins.js", id: "plugins-script"},
        {src: "/js/scripts.js", id: "scripts-script"},
    ]);


    return (

        <body className="hidden">
        <main>
            <Preloader/>

            <div className="cd-index cd-main-content">
                <div id="page-content" className="light-content">
                    <Header/>

                    <div id="main">

                        <div id="hero" className="">
                            <div id="hero-styles" className="parallax-onscroll">
                                <div id="hero-caption" className="">
                                    <div className="inner">
                                        <h1 className="hero-title">RUF VISIONARY AGENCY
                                            <br className="destroy"/><span>NE YAPAR?</span></h1>
                                        <h6>Öncelikle şunu diyelim: ruf, itibar demek... Peki ama neden itibar değil de
                                            ruf. Hem
                                            nece ki bu? Evet, ruf Almanca. Peki ama neden Türkçe değil? İtiraf ediyoruz
                                            itibar
                                            ajans deseydik bu kadar fiyakalı durmuyordu..</h6>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div id="main-content">
                            <div id="main-page-content">

                                <div className="vc_row">
                                    <div className="container">

                                        <h2>Markanızın itibarını ruf ile daha da büyütmenizi istiyoruz. Biz de sizinle
                                            büyümek
                                            itibarımızı artırmak gayesindeyiz. Bu sunumu sizlere hazırlarken fiyakalı
                                            bir sunum
                                            nasıl hazırlanır diye bir araştırma yaptık.</h2>


                                    </div>
                                </div>

                                <div className="vc_row row_padding_top row_padding_bottom">

                                    <div className="container">
                                        <h2 className="section-title">Uzmanlık Alanlarımız</h2>

                                        <div className="lead">
                                            <div style={{ color: "#da1266" }}>
                                                <h6>görsel tasarım</h6>
                                                <p>Bilboard, poster, afiş, broşür, katalog hazırlar.</p>
                                            </div>

                                            <div style={{ color: "#da1266" }}>
                                                <h6>fotoğraf</h6>
                                                <p>Reklam ve endüstriyel fotoğraf çekimleri yapar. (Büyük ve orta format fotoğraf makineleri gerekli durumlarda kullanılır)</p>
                                            </div>

                                            <div style={{ color: "#da1266" }}>
                                                <h6>video</h6>
                                                <p>Düşlediğiniz video, müzik klibi ya da TV reklam filmini gerçeğe dönüştürür.</p>
                                            </div>

                                            <div style={{ color: "#da1266" }}>
                                                <h6>retouch</h6>
                                                <p>Ürettiğiniz birbirinden değerli ürünleri daha güzel göstermek için son dokunuşu da yapar.</p>
                                            </div>

                                            <div style={{ color: "#da1266" }}>
                                                <h6>dijital medya</h6>
                                                <p>Markanızın dijital medya reklamlarını üretir, metnini yazar, planlamasını yapar. Yepyeni fikirler ile yoğun reklam trafiğinde öne çıkmasını sağlar.</p>
                                            </div>

                                            <div style={{ color: "#da1266" }}>
                                                <h6>kurumsal kimlik</h6>
                                                <p>Markanızın hedef kitlesine yönelik araştırma yapar ve en baştan kurumsal kimliğinizi yeniler. Logonuzu, kurumsal web sitenizi tasarlar.</p>
                                            </div>

                                            <div style={{ color: "#da1266" }}>
                                                <h6>ses tasarımı</h6>
                                                <p>Videoların daha etkileyici olması için müzik, ve ses efektleri üretir.</p>
                                            </div>

                                            <div style={{ color: "#da1266" }}>
                                                <h6>sosyal mecra</h6>
                                                <p>Markanın hedef kitlesine ulaşır. Sosyal medya hesaplarını açar, düzenler, metin yazar, video üretir ve trend olacak hashtagler üretir.</p>
                                            </div>

                                            <div style={{ color: "#da1266" }}>
                                                <h6>web tasarım</h6>
                                                <p>Dijital ortamda 7/25 açık olan iş yerinizi kurumsallığınıza uygun biçimde tasarlar.</p>
                                            </div>

                                            <div style={{ color: "#da1266" }}>
                                                <h6>animasyon</h6>
                                                <p>Ürününüzü tanıtmanız için kolay anlaşılacak animasyon videoları üretir. Markaların, TV programlarının logo ve açılış animasyonlarını yapar.</p>
                                            </div>
                                        </div>
                                    </div>


                                </div>


                            </div>

                        </div>
                    </div>

                    <Footer/>

                </div>
            </div>

        </main>


        </body>


);
}


export default About;
