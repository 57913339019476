const Header = () => {

    return (
        <header className="classic-menu">
            <div id="header-container">
                {/* Logo */}
                <div id="logo" className="hide-ball">
                    <a href="/" className="ajax-link" data-type="page-transition">
                        <img className="black-logo" src="/images/logo.png" alt="Ruf Logo"/>
                        <img className="white-logo" src="/images/logo-white.png" alt="Ruf Logo"/>
                    </a>
                </div>
                {/* Navigation */}
                <nav>
                    <div className="nav-height">
                        <div className="outer">
                            <div className="inner">
                                <ul data-breakpoint="1025" className="flexnav">
                                    <li className="link menu-timeline active">
                                        <a className="link" href="/">Anasayfa</a>
                                    </li>
                                    <li className="link menu-timeline">
                                        <a className="ajax-link link" data-type="page-transition"
                                           href="/hakkimizda">Hakkımızda</a>
                                    </li>
                                    <li className="link menu-timeline active">
                                        <a className="link" href="/" onClick={(e) => e.preventDefault()}>Kategoriler</a>
                                        <ul>
                                            <li><a className="ajax-link" href="/otomobil"
                                                   data-type="page-transition">Otomobil</a></li>
                                            <li><a className="ajax-link" href="/endustriyel"
                                                   data-type="page-transition">Endüstriyel</a></li>
                                            <li><a className="ajax-link" href="/mucevhersaat"
                                                   data-type="page-transition">Mücevher&Saat</a></li>
                                            <li><a className="ajax-link" href="/gida"
                                                   data-type="page-transition">Gıda</a></li>
                                            <li><a className="ajax-link" href="/portre"
                                                   data-type="page-transition">Portre</a></li>
                                            <li><a className="ajax-link" href="/video"
                                                   data-type="page-transition">Video</a></li>
                                        </ul>
                                    </li>
                                    <li className="link menu-timeline">
                                        <a className="ajax-link link" data-type="page-transition"
                                           href="/projeler">Projeler</a>
                                    </li>
                                    <li className="link menu-timeline">
                                        <a className="ajax-link link" data-type="page-transition"
                                           href="/iletisim">İletişim</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </nav>
                {/* Menu Burger */}
                <div id="burger-wrapper" className="parallax-wrap">
                    <div id="menu-burger" className="parallax-element">
                        <span></span>
                        <span></span>
                    </div>
                </div>
            </div>
        </header>
    )
}

export default Header;