import useLoadScript from "../hooks/useLoadScript";
import Preloader from "../components/Preloader";
import Header from "../components/Header";
import Footer from "../components/Footer";

function Contact() {
    useLoadScript([
        {src: "/js/jquery.min.js", id: "jquery-script"},
        {src: "/js/plugins.js", id: "plugins-script"},
        {src: "/js/scripts.js", id: "scripts-script"},
    ]);


    return (

        <body className="hidden">
        <main>
            <Preloader/>

            <div className="cd-index cd-main-content">
                <div id="page-content" className="light-content">
                    <Header/>

                    <div id="main">


                        <div id="hero" className="">
                            <div id="hero-styles" className="parallax-onscroll">
                                <div id="hero-caption" className="">
                                    <div className="inner">
                                        <h1 className="hero-title">Bizimle İletişime<span> Geç</span></h1><br/>
                                        <hr/>
                                        <br/><br/><br/>
                                        <iframe
                                            title="Ruf Agency - Google Maps Konumu"
                                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3112.116151660082!2d35.36585627788096!3d38.73809675608501!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x152b137f0cb77829%3A0x1070e7f5a959d315!2sRuf%20Agency%20-%20Ruf%20Ajans%20Kayseri!5e0!3m2!1str!2str!4v1689612658490!5m2!1str!2str"
                                            width="100%" height="450" frameBorder="0"
                                            style={{ border: 0 }}
                                            allowFullScreen
                                        ></iframe>

                                        <br/><br/>

                                    </div>
                                </div>
                            </div>
                        </div>


                        <div id="main-content">
                            <div id="main-page-content">


                                <div className="vc_row row_padding_bottom">

                                    <div className="container">

                                        <div className="one_half has-animation" data-delay="150">

                                            <br/>

                                            <h4>Bize dair her konu için ulaşabilirsiniz<br/>
                                                <a href="mailto:info@rufagency.com">info@rufagency.com</a> </h4>

                                        </div>

                                        <div className="one_half last has-animation" data-delay="200">
                                        <div id="contact-formular">
                                                <div className="clapat-icon">
                                                    <i className="fa fa-map-marker fa-2x" aria-hidden="true"></i>
                                                    <h5><a href="https://maps.app.goo.gl/FHnA3u31J62CQHq37"> Organize Sanayi Bölgesi 9. Cad. No:8
                                                        Melikgazi/KAYSERİ</a> </h5>
                                                    <h6>Adres</h6>
                                                </div>
                                                <div className="clapat-icon">
                                                    <i className="fa fa-phone fa-2x" aria-hidden="true"></i>
                                                    <h5><a href="tel:05377753636">0537 775 3636</a></h5>
                                                    <h6 >Telefon</h6>
                                                </div>

                                            </div>
                                        </div>

                                    </div>

                                </div>


                            </div>

                        </div>
                    </div>

                    <Footer/>

                </div>
            </div>

        </main>


        </body>


    );
}


export default Contact;
