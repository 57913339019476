import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation } from 'react-router-dom';
import useInstallPrompt from './hooks/useInstallPrompt';
import InstallPromptComponent from './components/InstallPromptComponent';

import Anasayfa from "./pages/anasayfa";
import Otomobil from "./pages/otomobil";
import Endustriyel from "./pages/endustriyel";
import Mucevhersaat from "./pages/mucevhersaat";
import Gida from "./pages/gida";
import Video from "./pages/video";
import Portre from "./pages/portre";
import About from "./pages/about";
import Contact from "./pages/contact";
import Projects from "./pages/projects";

import ReactGA4 from 'react-ga4';
const TRACKING_ID = "G-9N3HQMQXJT"; // Google Analytics 4 measurement ID
ReactGA4.initialize(TRACKING_ID);

function PageTracker() {
    let location = useLocation();
    React.useEffect(() => {
        ReactGA4.send({ hitType: "pageview", page: location.pathname + location.search });
    }, [location]);
    return null;
}

function App() {
    const [promptEvent] = useInstallPrompt();
    const [isPromptVisible, setPromptVisible] = useState(true); // Kurulum istemi görünürlüğü için yeni bir durum

    // onClose fonksiyonu ile kurulum istemi bileşenini gizleyecek fonksiyon
    const handleCloseInstallPrompt = () => setPromptVisible(false);

    return (
        <Router>
            <PageTracker />
            {promptEvent && isPromptVisible && (
                <InstallPromptComponent
                    promptEvent={promptEvent}
                    onClose={handleCloseInstallPrompt} // onClose prop'una fonksiyonu geçir
                />
            )}
            <Routes>
                <Route path="/" element={<Navigate replace to="/anasayfa" />} />
                <Route path="/anasayfa" element={<Anasayfa />} />
                <Route path="/otomobil" element={<Otomobil />} />
                <Route path="/endustriyel" element={<Endustriyel />} />
                <Route path="/mucevhersaat" element={<Mucevhersaat />} />
                <Route path="/gida" element={<Gida />} />
                <Route path="/portre" element={<Portre />} />
                <Route path="/video" element={<Video />} />
                <Route path="/iletisim" element={<Contact />} />
                <Route path="/hakkimizda" element={<About />} />
                <Route path="/projeler" element={<Projects />} />
            </Routes>
        </Router>
    );
}

export default App;
