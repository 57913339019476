import useLoadScript from "../hooks/useLoadScript";
import Preloader from "../components/Preloader";
import Header from "../components/Header";
import Footer from "../components/Footer";
import axios from "../axios";
import {useEffect, useState} from "react";

function Anasayfa() {
    useLoadScript([
        {src: "/js/jquery.min.js", id: "jquery-script"},
        {src: "/js/plugins.js", id: "plugins-script"},
        {src: "/js/scripts.js", id: "scripts-script"},
    ]);

    const [categoryImages, setCategoryImages] = useState([]);

    useEffect(() => {
        const fetchCategoryImages = async () => {
            try {
                const response = await axios.get('/sliders'); // API endpoint'iniz
                setCategoryImages(response.data);
            } catch (error) {
                console.error('Kategori görselleri getirilirken bir hata oluştu:', error);
            }
        };

        fetchCategoryImages();
    }, []);

    const getCategorySlideNumber = (categoryName) => {
        const mapping = {
            'otomobil': '1',
            'endüstriyel': '2',
            'mücevher': '3',
            'gıda': '4',
            'portre': '5',
            'video': '6',
        };
        return mapping[categoryName] || '0'; // Eğer eşleşme yoksa, varsayılan olarak '0' döndür
    };


    const navigateTo = (path) => {
        return () => window.location.href = path;
    };


    return (

        <body className="hidden">
        <main>
            <Preloader/>

            <div className="cd-index cd-main-content">
                {/* Page Content */}
                <div id="page-content" className="light-content">
                    <Header/>


                    <div id="main">

                        <div id="main-content" className="split-slider">


                            <div id="showcase-holder" className="disabled">
                                <div id="showcase-slider" className="swiper-container">
                                    <div className="swiper-wrapper">


                                        <div className="swiper-slide" data-number="01" data-slide="1"
                                             onClick={navigateTo('/otomobil')}>
                                            <div className="slide-title" data-number="01"><span>Otomobil</span></div>

                                        </div>

                                        <div className="swiper-slide" data-number="02" data-slide="2"
                                             onClick={navigateTo('/endustriyel')}>
                                            <div className="slide-title" data-number="02"><span>Endüstriyel</span></div>

                                        </div>

                                        <div className="swiper-slide" data-number="03" data-slide="3"
                                             onClick={navigateTo('/mucevhersaat')}>
                                            <div className="slide-title" data-number="03"><span>Mücevher & Saat</span>
                                            </div>

                                        </div>

                                        <div className="swiper-slide" data-number="04" data-slide="4"
                                             onClick={navigateTo('/gida')}>
                                            <div className="slide-title" data-number="04"><span>Gıda</span></div>

                                        </div>

                                        <div className="swiper-slide" data-number="05" data-slide="5"
                                             onClick={navigateTo('/portre')}>
                                            <div className="slide-title" data-number="05"><span>Portre</span></div>

                                        </div>

                                        <div className="swiper-slide" data-number="06" data-slide="6"
                                             onClick={navigateTo('/video')}>
                                            <div className="slide-title" data-number="06"><span>Video & Animasyon</span>
                                            </div>

                                        </div>


                                    </div>
                                </div>
                            </div>

                            <div id="image-slider">
                                <div className="image-slider-wrapper">

                                    {categoryImages.map((categoryImage) => (
                                        <div
                                            key={categoryImage.categoryName}
                                            className="slider-img"
                                            data-slide={getCategorySlideNumber(categoryImage.categoryName)}
                                            data-src={`${process.env.REACT_APP_API_ENDPOINT}/${categoryImage.imageUrl}`}
                                            style={{ backgroundImage: `url(${process.env.REACT_APP_API_ENDPOINT}/${categoryImage.imageUrl})` }}
                                        />
                                    ))}


                                </div>
                            </div>


                        </div>
                    </div>
                    <Footer/>


                </div>
            </div>
        </main>


        </body>


    );
}


export default Anasayfa;
