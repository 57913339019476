import { useEffect } from 'react';

const useLoadScript = (scripts) => {
    useEffect(() => {
        const loadScript = (src, id) => {
            return new Promise((resolve, reject) => {
                if (document.getElementById(id)) {
                    resolve();
                    return;
                }
                const script = document.createElement('script');
                script.src = src;
                script.async = true;
                script.id = id;
                script.onload = resolve;
                script.onerror = reject;
                document.body.appendChild(script);
            });
        };

        const loadScriptsAsync = async () => {
            try {
                for (let script of scripts) {
                    await loadScript(script.src, script.id);
                    console.log(`${script.id} yüklendi.`);
                }
                console.log("Tüm scriptler yüklendi.");
            } catch (error) {
                console.error("Script yükleme hatası:", error);
            }
        };

        loadScriptsAsync();
    }, [scripts]); // scripts dizisini bağımlılık olarak ekleyin
};

export default useLoadScript;
