import useLoadScript from "../hooks/useLoadScript";
import Preloader from "../components/Preloader";
import Header from "../components/Header";
import Footer from "../components/Footer";
import {useEffect, useState} from "react";
import axios from "../axios";
import './Video.css';

function Video() {
    useLoadScript([
        {src: "/js/jquery.min.js", id: "jquery-script"},
        {src: "/js/plugins.js", id: "plugins-script"},
        {src: "/js/scripts.js", id: "scripts-script"},
    ]);

    const [videos, setVideos] = useState([]);

    useEffect(() => {
        axios.get('/videos')
            .then(response => {
                setVideos(response.data.reverse());
            })
            .catch(error => {
                console.error('Error fetching videos with axios: ', error);
            });
    }, []);

    // Function to extract YouTube video ID from URL
    const extractVideoId = (url) => {
        const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&]*).*/;
        const match = url.match(regExp);
        return (match && match[7].length === 11) ? match[7] : null;
    };

    return (

        <body className="hidden">
        <main>
            <Preloader/>

            <div className="cd-index cd-main-content">
                <div id="page-content" className="light-content">
                    <Header/>

                    <div id="main">

                        <div id="hero" className="has-image">
                            <div id="hero-styles" className="parallax-onscroll">
                                <div id="hero-caption" className="text-align-center">
                                    <div className="inner">
                                        <h1 className="hero-title">Video & Animasyon</h1>

                                        <div className="scroll-down-wrap no-border link">
                                            <a href="/" className="section-down-arrow ">
                                                <svg className="nectar-scroll-icon" viewBox="0 0 30 45"
                                                     enableBackground="new 0 0 30 45">
                                                    <path className="nectar-scroll-icon-path" fill="none"
                                                          stroke="#ffffff"
                                                          strokeWidth="2" strokeMiterlimit="10"
                                                          d="M15,1.118c12.352,0,13.967,12.88,13.967,12.88v18.76  c0,0-1.514,11.204-13.967,11.204S0.931,32.966,0.931,32.966V14.05C0.931,14.05,2.648,1.118,15,1.118z">
                                                    </path>
                                                </svg>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id="hero-bg-wrapper">
                            <div id="hero-image-parallax">
                                <div id="hero-bg-image" style={{backgroundImage: "url('images/06hero.jpg')"}}></div>
                            </div>
                        </div>


                        <div className="vc_row row_padding_top row_padding_bottom">

                            <div className="container has-animation" data-delay="10">

                                <div className=" one_third"><h3>Video & Animasyon</h3></div>

                                <div className=" two_third last">
                                    <p>Görsel hikayenizi en etkileyici şekilde anlatmak için buradayız! Ajans olarak,
                                        video ve animasyon hizmetlerimizle markanızın öyküsünü, ürünlerinizi ve
                                        projelerinizi en etkileyici şekilde hayata geçiriyoruz.
                                        <br/>
                                        Dış çekim videolarımızla, doğal ışığın ve mekanların güzelliğini yakalıyoruz.
                                        Her bir karenin profesyonelce çekilmesi ve düzenlenmesiyle, markanızın dış
                                        mekanlarda en iyi şekilde temsil edilmesini sağlıyoruz.
                                        <br/>
                                        Stüdyoda çektiğimiz videolarımızda, ışıklandırma ve arka plan seçimi gibi
                                        detaylara özel önem veriyoruz. Ürünlerinizin ve projelerinizin en iyi açıdan ve
                                        en iyi ışık altında gösterilmesini sağlıyoruz.
                                        <br/>
                                        Ajans olarak, video ve animasyon hizmetlerimizle markanızın öyküsünü en
                                        etkileyici şekilde anlatıyoruz. Her bir proje için özel olarak planlanmış ve
                                        üretilmiş görsel içeriklerimizle, müşterilerimizin beklentilerini en iyi şekilde
                                        karşılıyoruz.</p>
                                </div>

                            </div>

                        </div>

                        <div className="video-container">
                            {videos.reverse().map((video) => (
                                <div key={video._id} className="video-wrap">
                                    <iframe
                                        width="560"
                                        height="315"
                                        src={`https://www.youtube.com/embed/${extractVideoId(video.url)}`}
                                        frameBorder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        allowFullScreen
                                        title={video.title}
                                    ></iframe>
                                </div>
                            ))}
                        </div>


                    </div>

                    <Footer/>

                </div>
            </div>

        </main>


        </body>


    );
}


export default Video;
