import useLoadScript from "../hooks/useLoadScript";
import Preloader from "../components/Preloader";
import Header from "../components/Header";
import Footer from "../components/Footer";
import {useEffect, useState} from "react";
import axios from "../axios";

function Projects() {
    const [pdfData, setPdfData] = useState([]); // PDF verilerini saklamak için state

    useLoadScript([
        {src: "/js/jquery.min.js", id: "jquery-script"},
        {src: "/js/plugins.js", id: "plugins-script"},
        {src: "/js/scripts.js", id: "scripts-script"},
    ]);


    useEffect(() => {
        axios.get('/pdfs')
            .then(response => {
                setPdfData(response.data);
            })
            .catch(error => {
                console.error('There was an error!', error);
            });
    }, []);


    return (

        <body className="hidden">
        <main>
            <Preloader/>

            <div className="cd-index cd-main-content">
                <div id="page-content" className="light-content">
                    <Header/>

                    <div id="main">

                        <div id="hero" className="">
                            <div id="hero-styles" className="parallax-onscroll">
                                <div id="hero-caption" className="">
                                    <div className="inner">

                                    </div>
                                </div>
                            </div>
                        </div>


                        <div
                            className="fooGrid"
                            style={{
                                display: "grid",
                                gridTemplateColumns: "repeat(3, 1fr)",
                                gridGap: "1em",
                                textAlign: "center"
                            }}
                        >
                            {pdfData.map((pdf) => ( // State'deki veriyle map fonksiyonu
                                <div key={pdf._id}>
                                    <a href={pdf.pdfLink} target="_blank" rel="noopener noreferrer">
                                        <img alt={pdf.title}
                                             src={`https://rufbackend-5yjx.onrender.com/${pdf.coverImage.replace('/var/data/', '')}`}/>
                                    </a>
                                </div>
                            ))}
                        </div>


                    </div>

                    <Footer/>

                </div>
            </div>

        </main>


        </body>


    );
}


export default Projects;
