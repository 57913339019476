const Preloader = () => {
    return (


        <div className="preloader-wrap">
            <div className="outer">
                <div className="inner">
                    <div className="percentage" id="precent"></div>
                </div>
            </div>
        </div>

    )

}
export default Preloader;