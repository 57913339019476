import React from 'react';
import PropTypes from 'prop-types';
import './InstallPromptComponent.css';

const InstallPromptComponent = ({ promptEvent, onClose }) => {
    if (!promptEvent) return null;

    const handleInstall = () => {
        promptEvent.prompt();
        promptEvent.userChoice.then((choiceResult) => {
            if (choiceResult.outcome === 'accepted') {
                console.log('Kullanıcı A2HS teklifini kabul etti.');
            } else {
                console.log('Kullanıcı A2HS teklifini reddetti.');
            }
            onClose(); // Bu noktada onClose fonksiyonu çağrılıyor
        });
    };

    const handleClose = () => {
        console.log('Kapat butonuna basıldı.', onClose);
        if (typeof onClose === 'function') {
            onClose(); // onClose fonksiyonunu sadece fonksiyon olduğunda çağır
        } else {
            console.error('onClose bir fonksiyon değil:', onClose);
        }
    };

    return (
        <div className="installPrompt">
            <img src="/images/logo-white.png" alt="Logo" className="promptLogo"/>
            <p>#Ruftayız! <br/>Uygulamamızı Yükleyin</p>
            <button onClick={handleInstall}>Yükle</button>
            {/* onClose fonksiyonunu doğrudan çağırmak yerine, handleClose fonksiyonunu kullan */}
            <button onClick={handleClose} className="closeButton">X</button>
        </div>
    );
};

InstallPromptComponent.propTypes = {
    promptEvent: PropTypes.shape({
        prompt: PropTypes.func.isRequired,
        userChoice: PropTypes.shape({
            then: PropTypes.func.isRequired,
        }).isRequired,
    }),
    onClose: PropTypes.func.isRequired,
};

// Buraya defaultProps tanımını ekleyin
InstallPromptComponent.defaultProps = {
    onClose: () => {}, // onClose için varsayılan boş bir fonksiyon tanımlayın
};

export default InstallPromptComponent;
