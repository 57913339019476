const Footer = () => {
    return (
        <footer className="fixed">
            <div id="footer-container">

                <div id="counter-wrap" data-hover="06">
                    <span data-slide-count="1">01</span>
                    <span data-slide-count="2">02</span>
                    <span data-slide-count="3">03</span>
                    <span data-slide-count="4">04</span>
                    <span data-slide-count="5">05</span>
                    <span data-slide-count="6">06</span>
                </div>

                <div className="socials-wrap">
                    <div className="socials-icon"><i className="fa fa-share-alt" aria-hidden="true"></i>
                    </div>
                    <div className="socials-text">Takip Et</div>
                    <ul className="socials">
                        <li><span className="parallax-wrap"><a className="parallax-element"
                                                               href="https://www.facebook.com/rufagency"
                                                               target="_blank" rel="noreferrer"  >Fb</a></span></li>
                        <li><span className="parallax-wrap"><a className="parallax-element"
                                                               href="https://www.instagram.com/rufagency"
                                                               target="_blank" rel="noreferrer" >In</a></span></li>

                        <li><span className="parallax-wrap"><a className="parallax-element"
                                                               href="https://www.youtube.com/channel/UCBlvScKWOKvmrMm7YhavmCw"
                                                               target="_blank" rel="noreferrer" >Yt</a></span></li>
                    </ul>
                </div>

            </div>
        </footer>
    )
}
export default Footer;
