import useLoadScript from "../hooks/useLoadScript";
import Preloader from "../components/Preloader";
import Header from "../components/Header";
import Footer from "../components/Footer";
import React, {useEffect, useState} from "react";
import axios from "../axios";
import Modal from "react-modal";
function MucevherSaat() {
    useLoadScript([
        {src: "/js/jquery.min.js", id: "jquery-script"},
        {src: "/js/plugins.js", id: "plugins-script"},
        {src: "/js/scripts.js", id: "scripts-script"},
    ]);
    const [images, setImages] = useState([]);
    const [modalIsOpen, setIsOpen] = useState(false);
    const [photoIndex, setPhotoIndex] = useState(0);

    useEffect(() => {
        axios.get('/mucevher/')
            .then(response => {
                let imageUrls = response.data.map(item => ({
                    src: `https://rufbackend-5yjx.onrender.com/mucevher/${item.image.split('/').pop()}`, // Adjust your image URL
                    width: item.width || 4,
                    height: item.height || 3
                }));
                imageUrls = imageUrls.reverse();
                setImages(imageUrls);
            })
            .catch(error => {
                console.error('Error fetching data with axios: ', error);
            });
    }, []);

    const openModal = (index) => {
        setPhotoIndex(index);
        setIsOpen(true);
    }

    const closeModal = () => {
        setIsOpen(false);
    }

    return (

        <body className="hidden">
        <main>
            <Preloader/>

            <div className="cd-index cd-main-content">
                <div id="page-content" className="light-content">
                    <Header/>
                    <div id="main">

                        <div id="hero" className="has-image">
                            <div id="hero-styles" className="parallax-onscroll">
                                <div id="hero-caption" className="text-align-center">
                                    <div className="inner">
                                        <h1 className="hero-title">Mücevher & Saat</h1>

                                        <div className="scroll-down-wrap no-border link">
                                            <a href="/" className="section-down-arrow ">
                                                <svg className="nectar-scroll-icon" viewBox="0 0 30 45"
                                                     enableBackground="new 0 0 30 45">
                                                    <path className="nectar-scroll-icon-path" fill="none"
                                                          stroke="#ffffff"
                                                          strokeWidth="2" strokeMiterlimit="10"
                                                          d="M15,1.118c12.352,0,13.967,12.88,13.967,12.88v18.76  c0,0-1.514,11.204-13.967,11.204S0.931,32.966,0.931,32.966V14.05C0.931,14.05,2.648,1.118,15,1.118z">
                                                    </path>
                                                </svg>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id="hero-bg-wrapper">
                            <div id="hero-image-parallax">
                                <div id="hero-bg-image" style={{backgroundImage: "url('images/03hero.jpg')"}}></div>
                            </div>
                        </div>

                        <div className="vc_row row_padding_top row_padding_bottom">

                            <div className="container has-animation" data-delay="10">

                                <div className=" one_third"><h3>Mücevher & Saat </h3></div>

                                <div className=" two_third last">
                                    <p>Ajans olarak, mücevher ve saat sektöründe faaliyet gösteren markaların
                                        ihtiyaçlarına uygun çözümler sunuyoruz. Her işimizde, müşteri memnuniyetini ve
                                        kaliteyi ön planda tutarak, mücevher ve saatlerin estetik güzelliğini en iyi
                                        şekilde yansıtıyoruz.</p>
                                </div>

                            </div>

                        </div>


                        <div className="gallery">
                            {images.map((image, i) => (
                                <img key={i} src={image.src} onClick={() => openModal(i)} alt=""/>
                            ))}
                        </div>
                        {modalIsOpen && images[photoIndex] && (
                            <Modal
                                isOpen={modalIsOpen}
                                onRequestClose={closeModal}
                                contentLabel="Image Modal"
                                style={{
                                    overlay: {
                                        backgroundColor: 'rgba(0, 0, 0, 0.75)'
                                    },
                                    content: {
                                        top: '50%',
                                        left: '50%',
                                        right: 'auto',
                                        bottom: 'auto',
                                        marginRight: '-50%',
                                        transform: 'translate(-50%, -50%)',
                                        width: '80%',
                                        height: '80%',
                                        border: 'none',
                                        background: 'none',
                                    }
                                }}
                            >
                                <img src={images[photoIndex].src} alt=""/>
                                <button onClick={closeModal}>Kapat</button>
                            </Modal>
                        )}


                    </div>


                    <Footer/>

                </div>
            </div>

        </main>


        </body>


    );
}


export default MucevherSaat;
